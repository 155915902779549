<template>
  <div class="background">
    <div v-for="(word, index) in searchTerms" :key="index" class="word" @click=changeSearch(word)>
      {{ word }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import lax from "lax.js";

export default {
  name: "Background",
  data() {
    return {
      searchTerms: [],
    };
  },
  mounted() {
    this.generateWords().then(() => {
      this.placeWords();
      this.animateWords();
    });
  },
  methods: {
    changeSearch(word) {
        this.$emit('changeSearch',word)
    },
    async generateWords() {
      return axios
        .get("https://random-word-api.herokuapp.com/word?number=100")
        .then((response) => {
          this.searchTerms = response.data;
        });
    },
    placeWords() {
      this.$el.childNodes.forEach((wordElement) => {
        const randomPosition = this.getRandomPosition(wordElement);
        wordElement.style.position = "absolute";
        wordElement.style.top = randomPosition.top;
        wordElement.style.left = randomPosition.left;
      });
    },
    animateWords() {
      // Setup mouse move listener
      document.addEventListener(
        "mousemove",
        (e) => {
          lax.__cursorX = e.clientX;
          lax.__cursorY = e.clientY;
        },
        false
      );
      // Add lax driver for cursorX
      lax.addDriver("cursorX", () => {
        return lax.__cursorX || 0;
      });

      // Add lax driver for cursorY
      lax.addDriver("cursorY", () => {
        return lax.__cursorY || 0;
      });

      let options = {
        cursorX: {
          translateX: [
            [0, "screenWidth"],
            ["index * 10", "index * -10"],
          ],
        },
        cursorY: {
          translateY: [
            [0, "screenHeight"],
            ["index * 10", "index * -10"],
          ],
        },
      };
      lax.addElements(".word", options);
      lax.addElements(".background", {
        cursorX: {
          filter: [
            [0, "screenWidth"],
            [0, "screenWidth/2"],
            {
              cssFn: (val) => {
                return `hue-rotate(${val % 360}deg)`;
              },
            },
          ],
        },
        cursorY: {
          filter: [
            [0, "screenHeight"],
            [0, "screenHeight/2"],
            {
              cssFn: (val) => {
                return `hue-rotate(${val % 360}deg)`;
              },
            },
          ],
        },
      });
    },
    getRandomPosition(element) {
      const x = element.offsetParent.clientHeight - element.clientHeight;
      const y = element.offsetParent.clientWidth;
      const randomX = Math.floor(Math.random() * x);
      const randomY = Math.floor(Math.random() * y);
      return { top: randomX + "px", left: randomY + "px" };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background {
  text-align: center;
  width: 100vw;
  height: 100vh;
  transform-origin: center;
  position: fixed;
  left: 0;
  font-size: 40px;
  color: blue;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  border-radius: 50px;
}
.word {
  background-color: blue;
  color: white;
  border-radius: 30px;
  cursor:pointer;
}
</style>
