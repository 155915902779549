<template>
  <div>
    <input
      v-if="this.search && !this.query"
      class="share"
      :value="shareUrl"
      readonly
    />
    <input
      v-model="search"
      id="input"
      placeholder="type your search here"
      autocomplete="false"
      autofocus
    />
    <Background v-if="activeBackground" @changeSearch="changeSearch"></Background>
  </div>
</template>

<script>
import lax from "lax.js";
import Background from "../components/Background.vue";

export default {
  name: "App",
  components: {
    Background,
  },
  data() {
    return {
      query: "",
      search: "",
      activeBackground:false,
    };
  },
  mounted() {
    this.setupAnimation();
    this.query = this.$route.query.p;
    if(this.$route.query.background==="true") {
      this.activeBackground=true;
    }
    if (this.query) {
      this.fillText(this.query).then(() => this.launchRotate());
    }
  },
  computed: {
    shareUrl() {
      let response = ''
      if (this.search) {
        response = window.location.href.split("?")[0] + "?p=" + this.search;
        if(this.activeBackground) {
          response+="&background=true"
        }
      }
      return response;
    },
  },
  methods: {
    changeSearch(word) {
      this.fillText(word)
    },
    launchRotate() {
      let increment = 1;
      lax.addDriver(
        "speedUp",
        function () {
          return increment;
        },
        {
          easing: "easeInQuart",
        }
      );
      lax.addElements("#input", {
        speedUp: {
          rotate: [
            [0, 300],
            [this.calcStartRotate(), 1800],
          ],
          filter: [
            [0, 300],
            [0, 1080],
            {
              cssFn: (val) => {
                return `hue-rotate(${val % 360}deg)`;
              },
            },
          ],
        },
      });
      let interval = setInterval(() => {
        increment++;
        if (increment > 300) {
          clearInterval(interval);
          window.location.href =
            "https://www.google.com/search?q=" + this.query;
        }
      }, 1);
    },
    async fillText(text) {
      this.search="";
      lax.addElements("#input", {
        inputLength: {
          rotate: [
            [0, 100],
            [0, 360],
          ],
        },
      });
      return new Promise((resolve) => {
        let i = 0;
        let interval = setInterval(() => {
          if (i >= text.length) {
            clearInterval(interval);
            resolve();
            return;
          }
          this.search += text[i];
          i++;
        }, 1000 / text.length);
      });
    },
    calcStartRotate() {
      return (this.query.length * 360) / 100;
    },
    setupAnimation() {
      lax.init();

      // Add lax driver for inputLength
      lax.addDriver("inputLength", () => {
        return this.search.length;
      });

      lax.addElements("#input", {
        inputLength: {
          rotate: [
            [0, 100],
            [0, 360],
          ],
          filter: [
            [0, 100],
            [0, 360],
            {
              cssFn: (val) => {
                return `hue-rotate(${val % 360}deg)`;
              },
            },
          ],
        },
      });
    },
  },
};
</script>

<style>
#input {
  text-align: center;
  width: calc(100vw - 200px);
  transform-origin: center;
  margin-left: 100px;
  margin-top: calc(50vh - 50px);
  position: fixed;
  left: 0;
  font-size: 40px;
  border: 0;
  outline: 0;
  background-color: #f544ad;
  padding: 20px;
  box-sizing: border-box;
  color: white;
  border-radius: 50px;
  z-index: 1;
}
.share {
  text-align: center;
  width: 50vw;
  transform-origin: center;
  margin-left: 25vw;
  margin-top: calc(80vh - 50px);
  position: fixed;
  left: 0;
  font-size: 40px;
  border: 0;
  outline: 0;
  background-color: #000;
  padding: 20px;
  box-sizing: border-box;
  color: white;
  border-radius: 50px;
  z-index: 2;
}
</style>
